import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface PageProps {
  data: {
    page: {
      title?: string
      path?: string
      seo: any
      flexContent: any
    }
  }
  location?: any
  prefix?: string
}

// Page template
const PageTemplate: React.FC<PageProps> = ({
  data: {
    page: { flexContent, seo },
  },
  location = {},
  prefix = 'page_Flexcontent',
}) => (
  <Layout location={location}>
    <SEO seo={seo} />

    <FlexContentHandler
      prefix={prefix}
      fields={flexContent}
      location={location}
    />

    <div className="py-4" />
  </Layout>
)

export const pageQuery = graphql`
  query PageById($id: String!, $previousPostId: String, $nextPostId: String) {
    page: wpPage(id: { eq: $id }) {
      ...generalPageFragment
    }

    previous: wpPage(id: { eq: $previousPostId }) {
      uri
      title
    }

    next: wpPage(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`

export default PageTemplate
